import client from './api-client'
import { getUrlParamValue } from './utils'

async function layersList() {
	const data = await client('layers/')

	if (process.env.REACT_APP_DASHBOARD === '1') {
		return data.map((layer) => {
				return {
					...layer,
					visible: (layer.name.startsWith('NATURAL') && layer.name.endsWith('WMS')) ? true : layer.is_init_visible,
				}
		})
	}

	return data.map((layer) => ({
		...layer,
		visible: layer.is_init_visible,
	}))
}

async function layersresolutionList(client_id) {
	const data = await client(`layersresolution?client_id=${client_id}`)

    if (process.env.REACT_APP_DASHBOARD === '1') {
		return data.map((res) => {
				return {
					...res,
					visible: ['30x30 centimeters'].includes(res.resolution) ? true : false,
				}
		})
	}

	// Override first visible resolution with url parameter
	const init_visible_resolution = getUrlParamValue('resolution')
	return data.map((res) => {
		if (init_visible_resolution) return {...res, visible: true ? init_visible_resolution === res.resolution : false}

		return {...res, visible: res.is_init_visible}
	})
}

export { layersList, layersresolutionList }
