import * as React from 'react'
import { useQuery } from 'react-query'
import { find } from 'lodash'
import { layersresolutionList } from '@graniot/common/adapters/layers'
import { useAuth } from '@graniot/common/context/AuthContext'

const ResolutionContext = React.createContext()

const resolutionReducer = (state, action) => {
	switch (action.type) {
		case 'SEED_RESOLUTIONS': {
			return action.payload.map((res, index) => {
				return {
					color: '#' + ((Math.random() * 0xffffff) << 0).toString(16),
					...res,
				}
			})
		}
		case 'TOGGLE_RESOLUTION': {
			return state.map((res) => {
				if (res.id === action.payload.id)
					return { ...res, visible: true }

				return { ...res, visible: false }
			})
		}
		case 'TOGGLE_MULTI_RESOLUTION': {
			const prevVisibleResolutions = state.filter(res => res.visible);
			const newState = state.map((res) => {
			if (res.id === action.payload.id)
				return { ...res, visible: !res.visible }
			return res
			});
			
			// Check if at least one resolution is visible
			const hasVisibleResolution = newState.some(res => res.visible);
			
			// If none are visible, keep one of the previously visible ones
			if (!hasVisibleResolution && prevVisibleResolutions.length > 0) {
			const keepVisibleId = prevVisibleResolutions[0].id;
			return state.map((res) => ({
				...res,
				visible: res.id === keepVisibleId
			}));
			}

			return newState.sort((a, b) => (
			a.id === action.payload.id ? -1 : b.id === action.payload.id ? 1 : 0
			));
		}
		default:
			return state
	}
}

function ResolutionProvider({ children }) {
	const { user } = useAuth()
	const { data: layersresolution } = useQuery({
		queryKey: ['layersresolution'],
		queryFn: () => layersresolutionList(user.id),
		refetchOnWindowFocus: false,
	})

	const [resolutions, dispatchResolution] = React.useReducer(
		resolutionReducer,
		[]
	)
	const [selectedResolution, setSelectedResolution] = React.useState(null)

	React.useEffect(() => {
		if (layersresolution) {
			dispatchResolution({
				type: 'SEED_RESOLUTIONS',
				payload: layersresolution,
			})
		}
	}, [layersresolution])

	React.useEffect(() => {
		if (resolutions) {
			setSelectedResolution(find(resolutions, { visible: true }))
		}
	}, [resolutions])

	// NOTE: you *might* need to memoize this value
	// Learn more in http://kcd.im/optimize-context
	const value = {
		resolutions,
		selectedResolution,
		setSelectedResolution,
		dispatchResolution,
	}

	return (
		<ResolutionContext.Provider value={value}>
			{children}
		</ResolutionContext.Provider>
	)
}

function useResolution() {
	const context = React.useContext(ResolutionContext)
	if (context === undefined) {
		throw new Error(
			`useResolution must be used within a ResolutionProvider`
		)
	}
	return context
}

export { ResolutionProvider, useResolution }
